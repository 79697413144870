import { toAbsoluteUrl } from "../../helpers/assetHelper";
import { TableItemType } from "./tableContext/TableContext";

function TableItemExtra({ extraData }: { extraData: TableItemType[] }) {
  return (
    <tr className="bg-light">
      {extraData?.map((item, index) => {
        return (
          <td key={index} {...item?.config} className="px-4">
            <div className="d-flex align-items-center">
              {item?.img && (
                <div className="symbol symbol-50px me-5">
                  <img
                    src={toAbsoluteUrl(item.img)}
                    alt={item?.title?.toString()}
                    className="mr-3"
                  />
                </div>
              )}
              <div className="d-flex justify-content-start flex-column">
                <p className="text-dark fw-bolder text-hover-primary mb-1 fs-6">
                  {item?.title}
                </p>

                {item?.subtitle && (
                  <span className="d-block fs-7 mb-1">{item?.subtitle}</span>
                )}
                {item?.badges &&
                  item?.badges?.length > 0 &&
                  item.badges.map((badge, idx) => {
                    return (
                      <span
                        key={idx}
                        className={`badge badge-${
                          badge?.badgeColor ?? "light-info"
                        } mb-2`}>
                        {badge?.badgeText}
                      </span>
                    );
                  })}
              </div>
            </div>
          </td>
        );
      })}
    </tr>
  );
}

export default TableItemExtra;
