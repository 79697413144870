import { memo } from "react";
import { KTSVG } from "../../../../../helpers/KTSVG";

import { TableItemId } from "../../../../../components/tables/TableWidget";
import CustomButton from "../../../../../components/CustomButton";

import { TableItemType } from "../../../../../components/tables/tableContext/TableContext";
import { expeditionRoutes } from "../../../../../router/routesConstants";
import { useAppDispatch } from "../../../../../redux/hooks";
import { setSelectedClientWorkingPoint } from "../../../../../redux/reducer/general.reducer";
import { useNavigate } from "react-router-dom";
import { ClientWorkingPointsDataInterface } from "../../../../../redux/interfaces/general.interface";

function ExpeditionScheduleWorkingPointsTableActionButtons({
  itemId,
  crudData,
}: {
  itemId: TableItemId;
  tableItemData: TableItemType[];
  crudData?: ClientWorkingPointsDataInterface;
  tableContext?: any;
}) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const goToOrganizeWorkingPoint = () => {
    dispatch(setSelectedClientWorkingPoint(crudData));

    navigate(
      `/${expeditionRoutes.clientWorkingPoints}/${expeditionRoutes.organize}?id=${itemId}&routeId=${crudData?.idPctLucru}&adress=${crudData?.adresa}&deliveryPoint=${crudData?.pctDeLivrare}`,
    );
  };

  return (
    <div>
      <CustomButton
        variant="contained"
        tooltipTitle="Organizare"
        className="mx-1 mb-1"
        onClick={goToOrganizeWorkingPoint}>
        <KTSVG
          path="/media/svg/reception/document.svg"
          className="svg-icon-1"
          fill="white"
        />
      </CustomButton>
    </div>
  );
}

export default memo(ExpeditionScheduleWorkingPointsTableActionButtons);
