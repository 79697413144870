import { useCallback } from "react";
import { crudRoutes } from "../../../router/routesConstants";
import TableWidget from "../../../components/tables/TableWidget";

import { TableActions } from "../../../components/tables/tableContext/TableContext";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { clearScheduleWorkingPoints } from "../../../redux/reducer/expedition.reducer";
import {
  getExpeditionScheduleWorkingPointsTableData,
  expeditionScheduleWorkingPointsTableHeader,
} from "./components/tableConfigs/expeditionScheduleWorkingPointsTableConfig";
import FormHeader from "../../../components/misc/FormHeader";
import { fetchShowClientsWorkingPoints } from "../../../redux/api/general.api";

import PickingScheduleWorkingPointsTableActionButtons from "./components/TableActionsButtonsComponents/ExpeditionScheduleWorkingPointsTableActionButtons";

function ClientWorkingPoints() {
  const dispatch = useAppDispatch();

  const {
    general: {
      clientWorkingPoints: { data: clientWorkingPointsData, isLoading },
    },
  } = useAppSelector((state) => ({
    general: state.general,
  }));

  const handleTableActions = useCallback(
    (action: TableActions) => {
      if (
        action.tablePagination.page &&
        action.tablePagination.rowsPerPage &&
        action.tableSearch
      ) {
        dispatch(
          fetchShowClientsWorkingPoints({
            page: action.tablePagination.page,
            perPage: action.tablePagination.rowsPerPage,
            searchAdresa: action.tableSearch?.searchAdresa,
            searchPctLucru: action.tableSearch?.searchPctLucru,
            searchJudet: action.tableSearch?.searchJudet,
            searchLocalitate: action.tableSearch?.searchLocalitate,
          }),
        );
      }
    },
    [dispatch],
  );

  const handleUnmount = useCallback(() => {
    dispatch(clearScheduleWorkingPoints());
  }, [dispatch]);

  return (
    <TableWidget
      CustomHeaderComponent={() => (
        <FormHeader
          title="Puncte de lucru"
          buttonRoute={crudRoutes.create}
          buttonIcon="ADD"
        />
      )}
      tableHeaderData={expeditionScheduleWorkingPointsTableHeader}
      tableItemsData={getExpeditionScheduleWorkingPointsTableData(
        clientWorkingPointsData?.data,
      )}
      handleTableActions={handleTableActions}
      handleUnmount={handleUnmount}
      totalItems={clientWorkingPointsData.total}
      tableLoading={isLoading}
      ActionButtonsComponent={PickingScheduleWorkingPointsTableActionButtons}
      borderedRow
    />
  );
}

export default ClientWorkingPoints;
