import { createPortal } from "react-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import Text from "../../styleguide/Text";
import SvgIcon from "../../helpers/SvgIcon";

type Props = {
  children: React.ReactNode;
  show: boolean;
  handleClose?: () => void;
  title?: string;
  modalSize?: "xs" | "sm" | "md" | "lg" | "xl";
  headerComponent?: (() => JSX.Element) | JSX.Element;
  actionsComponent?: (() => JSX.Element) | JSX.Element;
};

const modalsRoot = document.getElementById("root-modals") || document.body;

const ModalWrapper = ({
  children,
  show,
  handleClose,
  title,
  modalSize = "sm",
  headerComponent,
  actionsComponent,
}: Props): any => {
  return createPortal(
    <Dialog
      open={show}
      onClose={handleClose}
      maxWidth={modalSize}
      fullWidth
      scroll="body"
      sx={{ paddingTop: "70px" }}>
      {/* Header */}
      {(title || handleClose) && (
        <DialogTitle className="d-flex justify-content-between align-items-center">
          {title && (
            <Text variant="h2" component="h2">
              {title}
            </Text>
          )}
          {handleClose && (
            <IconButton
              onClick={handleClose}
              className="text-gray-500 hover:text-gray-900">
              <SvgIcon type="CLOSE_ICON" />
            </IconButton>
          )}
        </DialogTitle>
      )}

      {!!headerComponent && (
        <DialogTitle>
          {typeof headerComponent === "function"
            ? headerComponent()
            : headerComponent}
        </DialogTitle>
      )}

      <DialogContent className="py-10 px-10 border-t w-100">
        {children}
      </DialogContent>

      {!!actionsComponent && (
        <DialogActions>
          {typeof actionsComponent === "function"
            ? actionsComponent()
            : actionsComponent}
        </DialogActions>
      )}
    </Dialog>,
    modalsRoot,
  );
};

export default ModalWrapper;
