import React from "react";
import clsx from "clsx";

import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

import { DropdownValue } from ".";
import Text from "../../../styleguide/Text";
import LoadingIndicator from "../LoadingIndicator";
import SvgIcon from "../../../helpers/SvgIcon";

const icon = <SvgIcon type="CHECKBOX_OUTLINE_BLANK" />;
const checkedIcon = <SvgIcon type="CHECKBOX" />;

const classes = clsx("form-control form-control-solid mb-3 mb-lg-0");

const filter = createFilterOptions<any>();

export default function MultipleDropdown({
  data,
  multipleValues,
  setMultipleValues,
  name,
  title = " ",
  className,
  style,
  placeholder,
  isLoading,
  disabled,
  withVerticalSpacer,
  withAddNewValue,
  touched,
  error,
}: {
  data: DropdownValue[];
  multipleValues?: DropdownValue[];
  setMultipleValues?: (value: DropdownValue[]) => void;
  name?: string;
  title?: string;
  className?: string;
  style?: React.CSSProperties;
  placeholder?: string;
  isLoading?: boolean;
  disabled?: boolean;
  withVerticalSpacer?: boolean;
  withAddNewValue?: boolean;
  touched?: boolean;
  error?: string;
}) {
  const handleOnSelect = (_: any, values: any) => {
    if (values && setMultipleValues) {
      if (values.manual) {
        if (multipleValues) {
          setMultipleValues([
            ...multipleValues,
            { label: values.id, id: Math.random() * 1000000 },
          ]);
        }
      } else {
        setMultipleValues(values);
      }
    }
  };

  return (
    <div
      className={`fv-row mb-7 w-100 ${
        withVerticalSpacer ? "px-1" : ""
      } ${className}`}>
      {title && (
        <label
          htmlFor={`${name ?? title}-multiple-checkbox-label`}
          className="form-label fs-6 fw-bolder text-dark">
          <Text variant="body1" isBold>
            {title}
          </Text>
        </label>
      )}
      <Autocomplete
        multiple
        freeSolo={withAddNewValue}
        id={`${name ?? title}-multiple-checkbox-label`}
        style={style}
        fullWidth
        disabled={disabled}
        options={data}
        value={multipleValues ?? []}
        disableCloseOnSelect
        loading={isLoading}
        onChange={handleOnSelect}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;

          if (!withAddNewValue) {
            return filtered;
          }

          const isExisting = options.some((option) => inputValue === option.id);
          if (inputValue !== "" && !isExisting) {
            filtered.unshift({
              manual: true,
              id: inputValue,
              label: inputValue,
            });
          }

          return filtered;
        }}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          return option.label?.toString();
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option.id}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.label}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            className={classes}
            sx={{
              "& fieldset": { border: "none" },
            }}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isLoading ? (
                    <LoadingIndicator color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      {touched && error && (
        <div>
          <Text variant="body1" color="red" role="alert" className="mt-2">
            {error}
          </Text>
        </div>
      )}
    </div>
  );
}
