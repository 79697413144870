import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiRequest, errorFormatHandler } from "../../helpers/apiHelper";
import {
  ClientsWorkingPointsDataInterface,
  ClientWorkingPointsDataInterface,
  DeliveryServicesDataInterface,
} from "../interfaces/general.interface";
import { DropdownValue } from "../../components/misc/Dropdown";

export const fetchPackageTypes = createAsyncThunk(
  "/fetchPackageTypes",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await apiRequest(`/showDenumiri`, "GET", {
        table: "tbl_doctrans",
      });
      return data;
    } catch (err: any) {
      console.warn("/fetchPackageTypes", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);

export const fetchAwbHistory = createAsyncThunk(
  "/fetchAwbHistory",
  async (
    params: {
      page: number;
      perPage: number;
      pctlivrare?: string;
      nodDestinatie?: string;
      status?: number;
      doc_trans?: string;
    },
    { rejectWithValue, getState },
  ) => {
    const state: any = getState();

    const {
      user: {
        data: { id_client },
      },
    } = state;

    try {
      const { data } = await apiRequest("/displayAwb", "POST", {
        ...params,
        id_client,
      });
      return data;
    } catch (err: any) {
      console.warn("/fetchAwbHistory", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);

export const fetchBinHistory = createAsyncThunk(
  "/fetchBinHistory",
  async (
    params: {
      page: number;
      perPage: number;
    },
    { rejectWithValue, getState },
  ) => {
    const state: any = getState();

    const {
      user: {
        data: { id_client },
      },
    } = state;

    try {
      const { data } = await apiRequest("/displayBins", "POST", {
        ...params,
        id_client,
      });
      return data;
    } catch (err: any) {
      console.warn("/fetchBinHistory", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);

export const fetchCreateAwb = createAsyncThunk(
  "/fetchCreateAwb",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await apiRequest("/requestAwb", "POST");
      return data;
    } catch (err: any) {
      console.warn("/fetchCreateAwb", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);

export const fetchGetClientsWorkingPoints = createAsyncThunk(
  "/fetchGetClientsWorkingPoints",
  async (
    params: {
      page: number;
      perPage: number;
      searchPctLucru?: string;
      searchRuta?: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await apiRequest(
        "/displayClientiPctLucru",
        "POST",
        params,
      );
      return data;
    } catch (err: any) {
      console.warn("/fetchGetClientsWorkingPoints", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);

export const fetchGetClientsPickupPoints = createAsyncThunk(
  "/fetchGetClientsPickupPoints",
  async (
    params: {
      page: number;
      perPage: number;
      searchPctLucru?: string;
      searchRuta?: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await apiRequest("/displayClientiPctLucru", "POST", {
        ...params,
        pct_ridicare: 1,
      });
      return data;
    } catch (err: any) {
      console.warn("/fetchGetClientsPickupPoints", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);

export const fetchAddBin = createAsyncThunk(
  "/fetchAddBin",
  async (
    params: {
      id_nod_destinatie: string | number;
      cantitate: number | string;
      id_punctlucru?: number;
      selectedPickupPoint: ClientsWorkingPointsDataInterface;
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await apiRequest("/addBinBarcode", "POST", params);
      return data;
    } catch (err: any) {
      console.warn("/fetchAddBin", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);

export const fetchClientCreateAwb = createAsyncThunk(
  "/fetchClientCreateAwb",
  async (
    params: {
      packageTypes: DeliveryServicesDataInterface[];
      selectedWorkingPoint: ClientsWorkingPointsDataInterface;
      selectedPickupPoint: ClientsWorkingPointsDataInterface;
      invoicesList: DropdownValue[];
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await apiRequest("/clientRequestAwb", "POST", params);
      return data;
    } catch (err: any) {
      console.warn("/fetchClientCreateAwb", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);

export const fetchShowClientsWorkingPoints = createAsyncThunk(
  "/fetchShowClientsWorkingPoints",
  async (
    params: {
      perPage: number;
      page: number;
      searchAdresa?: string;
      searchPctLucru?: string;
      searchJudet?: string;
      searchLocalitate?: string;
    },
    { rejectWithValue, getState },
  ) => {
    try {
      const state: any = getState();
      const {
        user: {
          data: { id_client },
        },
      } = state;

      const { data } = await apiRequest("/showClientsWorkingPoints", "GET", {
        clientId: id_client,
        ...params,
      });
      return data;
    } catch (err: any) {
      console.warn("/fetchShowClientsWorkingPoints", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);

export const fetchCreateOrUpdateClientWorkingPoint = createAsyncThunk(
  "/fetchCreateOrUpdateClientWorkingPoint",
  async (
    params: Partial<ClientWorkingPointsDataInterface>,
    { rejectWithValue },
  ) => {
    try {
      const { data } = await apiRequest(
        "/createOrUpdateClientWorkingPoint",
        "POST",
        params,
      );
      return data;
    } catch (err: any) {
      console.warn("/fetchCreateOrUpdateClientWorkingPoint", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);

export const getServices = createAsyncThunk(
  "/getServices",
  async (
    params: {
      status?: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("/displayNomServices", "GET", params);

      return data;
    } catch (err: any) {
      console.warn("/getServices", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);
