import React from "react";
import useTableCtx from "../tableContext/useTableCtx";
import SvgIcon from "../../../helpers/SvgIcon";

function TableOrder({
  label,
  value,
  withOrder,
}: {
  label?: string;
  value: string;
  withOrder?: boolean;
}) {
  const { handleTableOrder, tableOrder } = useTableCtx()!;

  const tableOrderValue = tableOrder && tableOrder[value];

  const selectedValue = tableOrderValue ?? null;

  const isOrderAscending = selectedValue === "asc";

  return (
    <div
      className={`d-flex w-fc align-items-center ${
        withOrder ? "cursor-pointer" : ""
      } `}
      onClick={() => withOrder && handleTableOrder && handleTableOrder(value)}>
      {label ?? value}
      {!!withOrder && (
        <>
          <div className="d-flex flex-column ms-2">
            <SvgIcon
              type="KEYBOARD_ARROW_UP"
              className="svg-icon-2 mb-n2 mt-n2 h-18px"
              style={{
                height: 20,
                width: 20,
                opacity: isOrderAscending && 0.2,
              }}
            />
            <SvgIcon
              type="KEYBOARD_ARROW_DOWN"
              className="svg-icon-2 mt-n1 mb-n2"
              style={{
                height: 20,
                width: 20,
                opacity: !isOrderAscending && 0.2,
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default TableOrder;
