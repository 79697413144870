import { ReactNode, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";

import DrawerItems from "./DrawerItems";
import NavbarNavigationItems from "./NavbarNavigationItems";
import NavbarLogo from "./NavbarLogo";
import SvgIcon from "../../helpers/SvgIcon";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  // padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  // justifyContent: "flex-start",
}));

export default function NavbarPersistWrapper({
  children,
}: {
  children: ReactNode;
}) {
  const theme = useTheme();

  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className="d-flex h-100">
      <AppBar position="fixed" open={open} elevation={3}>
        <Toolbar>
          {!open && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start">
              <SvgIcon type="MENU_ICON" />
            </IconButton>
          )}
          {!open && <NavbarLogo withEnvIndicator />}
          <NavbarNavigationItems />
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}>
        <DrawerHeader>
          <div className="w-100 d-flex align-items-center justify-content-between">
            {open && <NavbarLogo />}

            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <SvgIcon type="CHEVRON_LEFT_ICON" />
              ) : (
                <SvgIcon type="CHEVRON_RIGHT_ICON" />
              )}
            </IconButton>
          </div>
        </DrawerHeader>
        <Divider />
        <DrawerItems
        // handleDrawerClose={handleDrawerClose}
        />
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        {children}
      </Main>
    </div>
  );
}
