import React from "react";
import TableHeaderCategories from "./components/TableHeaderCategories";
import { TableLoading } from "./components/TableLoading";
import ContentCard from "../misc/ContentCard";
import TableGlobalSearch from "./components/TableGlobalSearch";
import TableGlobalFilter from "./components/TableGlobalFilter";
import TableProvider from "./tableContext/TableProvider";
import {
  TableActions,
  TableData,
  TableFilterDataItem,
  TableHeaderCategoryConfig,
} from "./tableContext/TableContext";
import TablePagination from "./components/TablePagination";
import TableHeader from "./components/TableHeader";
import TableBody from "./components/TableBody";
import useTableConfig from "./hooks/useTableConfig";

export type TableItemId = string | number;

type Props = {
  className?: string;
  tableHeaderData: TableHeaderCategoryConfig[];
  tableItemsData: TableData[];
  globalFilter?: TableFilterDataItem[];
  withGlobalSearch?:
    | boolean
    | {
        placeholder?: string;
        searchDelay?: number;
      };
  withGlobalFilter?: boolean;
  borderedRow?: boolean;
  ActionButtonsComponent?: any;
  CustomHeaderComponent?: any;
  tableTitle?: string;
  showSearchForCategories?: boolean;
  withTablePagination?: boolean;
  handleTableActions?: (value: TableActions) => void;
  handleUnmount?: () => void;
  tableLoading?: boolean;
  totalItems?: number;
  emptyTableLabel?: string;
};

const TableWidget: React.FC<Props> = (props) => {
  return (
    <TableProvider>
      <TableComponent {...props} />
    </TableProvider>
  );
};

const TableComponent: React.FC<Props> = ({
  globalFilter,
  tableHeaderData,
  tableItemsData,
  className,
  withGlobalSearch,
  withGlobalFilter,
  borderedRow = true,
  ActionButtonsComponent,
  CustomHeaderComponent,
  tableTitle,
  showSearchForCategories = true,
  withTablePagination = true,
  handleTableActions,
  handleUnmount,
  tableLoading,
  totalItems,
  emptyTableLabel = "Tabelul este gol",
}) => {
  useTableConfig({
    handleTableActions,
    handleUnmount,
    globalFilter,
    tableHeaderData,
    tableItemsData,
  });

  return (
    <>
      <ContentCard
        cardBodyClassName={`card w-100 ${className}`}
        cardBodyStyle={{ padding: 0, margin: 0 }}
        cardHeaderClassName="card-header border-0 py-5 ps-1 text-left"
        cardTitleStyle={{ fontWeight: "bolder" }}
        cardTitle={tableTitle}
        CardHeader={() => <></>}>
        {/* begin::Table container */}
        <div className="table-responsive">
          {/* search start */}
          {(withGlobalSearch || withGlobalFilter) && (
            <div className="d-flex">
              {withGlobalSearch && (
                <TableGlobalSearch withGlobalSearch={withGlobalSearch} />
              )}
              {withGlobalFilter && <TableGlobalFilter />}
            </div>
          )}
          <TableHeader CustomHeaderComponent={CustomHeaderComponent} />

          {/* search end */}
          {/* begin::Table */}
          <table
            className={`table align-middle gs-0 gy-3 table-row-gray-100 ${
              borderedRow && "table-row-bordered"
            }`}>
            {/* begin::Table head */}
            <TableHeaderCategories
              showActionButtons={!!ActionButtonsComponent}
              showSearchForCategories={showSearchForCategories}
            />
            {/* end::Table head */}
            {/* begin::Table body */}
            <TableBody
              ActionButtonsComponent={ActionButtonsComponent}
              emptyTableLabel={emptyTableLabel}
            />
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
        {withTablePagination && <TablePagination totalItems={totalItems} />}

        {tableLoading && <TableLoading />}
      </ContentCard>
    </>
  );
};

export default TableWidget;
