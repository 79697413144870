import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useNavigate } from "react-router-dom";

import {
  fetchAddBin,
  fetchGetClientsPickupPoints,
} from "../../redux/api/general.api";

import ContentCard from "../../components/misc/ContentCard";
import TextInput from "../../components/misc/TextInput";
import FormButtons from "../../components/misc/FormButtons";
import Dropdown, { DropdownValue } from "../../components/misc/Dropdown";
import { checkEndpointStatus, endpointStatus } from "../../helpers/apiHelper";
import ModalList, {
  ModalListItem,
} from "../../components/misc/ModalList/ModalList";
import { ClientsWorkingPointsDataInterface } from "../../redux/interfaces/general.interface";
import { fetchGetClientsNodes } from "../../redux/api/expedition.api";

function CreateBin() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    general: {
      clientsPickupPoints: {
        data: { data: clientsPickupPointsData },
      },
    },
    expedition: {
      workingRoutesNodes: {
        data: { data: clientsNodesData },
      },
    },
  } = useAppSelector((state) => ({
    general: state.general,
    expedition: state.expedition,
  }));

  const [binQuantity, setBinQuantity] = useState<string | number | null>(null);

  const [selectedWorkingPoint, setSelectedWorkingPoint] =
    useState<DropdownValue | null>(null);
  const [selectedPickupPoint, setSelectedPickupPoint] =
    useState<ClientsWorkingPointsDataInterface | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(
      fetchGetClientsNodes({
        page: 1,
        perPage: 50,
      }),
    );
  }, [dispatch]);

  const handleProductSelected = (value: any) => {
    setSelectedWorkingPoint(value);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleAddBin = async () => {
    if (!selectedWorkingPoint?.id || !binQuantity || !selectedPickupPoint) {
      return;
    }

    setIsLoading(true);

    const resultAction = await dispatch(
      fetchAddBin({
        id_nod_destinatie: selectedWorkingPoint?.id,
        cantitate: binQuantity,
        selectedPickupPoint,
      }),
    );

    if (
      checkEndpointStatus(resultAction, fetchAddBin) !== endpointStatus.pending
    ) {
      setIsLoading(false);
    }

    if (
      checkEndpointStatus(resultAction, fetchAddBin) ===
      endpointStatus.fulfilled
    ) {
      navigate(-1);
    }
  };

  const pickupPointModalActions = ({ search }: { search: string }) => {
    dispatch(
      fetchGetClientsPickupPoints({
        page: 1,
        perPage: 50,
        searchPctLucru: search,
      }),
    );
  };

  const nodesDropdownData = clientsNodesData.map((item) => ({
    id: item.id_nod,
    label: item.nodNume,
  }));

  const initialClientsPickupPointsList: ModalListItem[] = useMemo(
    () =>
      clientsPickupPointsData.map((item) => {
        const { id_clienti_pctlucr: id, denumire: label } = item;
        return {
          ...item,
          label: `${label}`, // required
          id: id, // required
          backgroundColor:
            id === selectedPickupPoint?.id_clienti_pctlucr ? "warning" : "info",
        };
      }),
    [clientsPickupPointsData, selectedPickupPoint],
  );

  const disableSubmit =
    !selectedWorkingPoint || !binQuantity || !selectedPickupPoint;

  return (
    <ContentCard cardTitle="Formeaza BIN" isLoading={isLoading}>
      <div>
        <Dropdown
          className="my-4"
          title="Noduri"
          data={nodesDropdownData}
          singleValue={{
            id: selectedWorkingPoint?.id || "",
            label: selectedWorkingPoint?.label || "",
          }}
          setSingleValue={handleProductSelected}
        />
        <ModalList
          label="Punct de ridicare"
          searchPlaceholder={"Cauta punct de ridicare"}
          modalTitle={"Cauta punct de ridicare"}
          items={initialClientsPickupPointsList}
          modalActions={pickupPointModalActions}
          selectedItem={{
            id: selectedPickupPoint?.id_clienti_pctlucr || "",
            label: selectedPickupPoint?.denumire || "",
          }}
          setSelectedItem={setSelectedPickupPoint}
        />

        <div className="d-flex flex-column flex-md-row">
          <TextInput
            label={"Cantitate"}
            name={"cantitate"}
            type="number"
            value={binQuantity}
            onChange={(value) => setBinQuantity(value)}
            withVerticalSpacer
          />
        </div>
      </div>
      <FormButtons
        goBackLabel="Inapoi"
        goNextLabel="Salveaza"
        nextButtonDisabled={disableSubmit}
        handleGoBack={handleGoBack}
        handleGoNext={handleAddBin}
      />
    </ContentCard>
  );
}

export default CreateBin;
