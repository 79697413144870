import React from "react";
import { BinHistoryDataInterface } from "../../../../redux/interfaces/general.interface";
import { useAppDispatch } from "../../../../redux/hooks";
import {
  fetchPrintBinA4,
  fetchPrintBinZebra,
} from "../../../../redux/api/expedition.api";
import CustomButton from "../../../../components/CustomButton";
import SvgIcon from "../../../../helpers/SvgIcon";

function BinHistoryActionButtons({
  itemId,
}: {
  itemId: string;
  crudData: BinHistoryDataInterface;
}) {
  const dispatch = useAppDispatch();

  const handlePrintBinA4 = () => {
    if (itemId) {
      dispatch(
        fetchPrintBinA4({
          idBin: itemId,
        }),
      );
    }
  };

  const handlePrintBinZebra = () => {
    if (itemId) {
      dispatch(
        fetchPrintBinZebra({
          idBin: itemId,
        }),
      );
    }
  };

  return (
    <div>
      <CustomButton
        variant="contained"
        className="mt-1 me-1"
        tooltipTitle="Print A4"
        onClick={handlePrintBinA4}>
        <SvgIcon type="PRINT_A4" fill="white" />
      </CustomButton>

      <CustomButton
        variant="contained"
        className="mt-1 me-1"
        tooltipTitle="Print Zebra"
        onClick={handlePrintBinZebra}>
        <SvgIcon type="PRINT_ZEBRA" />
      </CustomButton>
    </div>
  );
}

export default BinHistoryActionButtons;
