import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useNavigate } from "react-router-dom";

import {
  fetchClientCreateAwb,
  fetchGetClientsPickupPoints,
  fetchGetClientsWorkingPoints,
  getServices,
} from "../../redux/api/general.api";
import {
  ClientsWorkingPointsDataInterface,
  DeliveryServicesDataInterface,
} from "../../redux/interfaces/general.interface";

import ContentCard from "../../components/misc/ContentCard";
import TextInput from "../../components/misc/TextInput";
import ModalList, {
  ModalListItem,
} from "../../components/misc/ModalList/ModalList";
import FormButtons from "../../components/misc/FormButtons";
import Dropdown, { DropdownValue } from "../../components/misc/Dropdown";
import { checkEndpointStatus, endpointStatus } from "../../helpers/apiHelper";

function CreateAwb() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    general: {
      delieryServices: {
        data: delieryServicesData,
        isLoading: delieryServicesLoading,
      },
      clientsWorkingPoints: {
        data: { data: clientsWorkingPointsData },
      },
      clientsPickupPoints: {
        data: { data: clientsPickupPointsData },
      },
    },
  } = useAppSelector((state) => ({
    general: state.general,
  }));

  const [isLoading, setIsLoading] = useState(false);
  const [packageTypes, setPackageTypes] = useState<
    DeliveryServicesDataInterface[]
  >([]);

  const [selectedWorkingPoint, setSelectedWorkingPoint] =
    useState<ClientsWorkingPointsDataInterface | null>(null);
  const [selectedPickupPoint, setSelectedPickupPoint] =
    useState<ClientsWorkingPointsDataInterface | null>(null);
  const [invoicesList, setInvoicesList] = useState<DropdownValue[]>([]);

  useEffect(() => {
    if (delieryServicesData) {
      setPackageTypes(delieryServicesData);
    }
  }, [delieryServicesData]);

  useEffect(() => {
    dispatch(
      getServices({
        status: 1,
      }),
    );
  }, [dispatch]);

  const onPackageChange = (
    value: string | number | null,
    id: number | string,
  ) => {
    setPackageTypes((prev) => {
      return prev.map((packageType) => {
        if (packageType.id_serviciu === id) {
          return {
            ...packageType,
            cantitate: value,
          };
        }
        return packageType;
      });
    });
  };

  const workingPointModalActions = ({ search }: { search: string }) => {
    dispatch(
      fetchGetClientsWorkingPoints({
        page: 1,
        perPage: 50,
        searchPctLucru: search,
      }),
    );
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleSubmit = async () => {
    if (!selectedWorkingPoint || !selectedPickupPoint || !packageTypes.length) {
      return;
    }

    setIsLoading(true);

    const filteredPackageTypes = packageTypes.filter(
      (packageType) => packageType.cantitate,
    );

    const resultAction = await dispatch(
      fetchClientCreateAwb({
        packageTypes: filteredPackageTypes,
        selectedWorkingPoint,
        selectedPickupPoint,
        invoicesList,
      }),
    );

    if (
      checkEndpointStatus(resultAction, fetchClientCreateAwb) !==
      endpointStatus.pending
    ) {
      setIsLoading(false);
    }

    if (
      checkEndpointStatus(resultAction, fetchClientCreateAwb) ===
      endpointStatus.fulfilled
    ) {
      navigate(-1);
    }
  };

  const pickupPointModalActions = ({ search }: { search: string }) => {
    dispatch(
      fetchGetClientsPickupPoints({
        page: 1,
        perPage: 50,
        searchPctLucru: search,
      }),
    );
  };

  const initialClientsWorkingPointsList: ModalListItem[] = useMemo(
    () =>
      clientsWorkingPointsData.map((item) => {
        const { id_clienti_pctlucr: id, denumire: label } = item;
        return {
          ...item,
          label: `${label}`, // required
          id: id, // required
          backgroundColor:
            id === selectedWorkingPoint?.id_clienti_pctlucr
              ? "warning"
              : "info",
        };
      }),
    [clientsWorkingPointsData, selectedWorkingPoint],
  );

  const initialClientsPickupPointsList: ModalListItem[] = useMemo(
    () =>
      clientsPickupPointsData.map((item) => {
        const { id_clienti_pctlucr: id, denumire: label } = item;
        return {
          ...item,
          label: `${label}`, // required
          id: id, // required
          backgroundColor:
            id === selectedPickupPoint?.id_clienti_pctlucr ? "warning" : "info",
        };
      }),
    [clientsPickupPointsData, selectedPickupPoint],
  );

  const withBoxesAdded = packageTypes.some(
    (packageType) => packageType.cantitate,
  );

  const isSubmitDisabled =
    isLoading ||
    !invoicesList.length ||
    !selectedWorkingPoint ||
    !selectedPickupPoint ||
    !packageTypes.length ||
    !withBoxesAdded;

  return (
    <ContentCard
      cardTitle="Plaseaza comanda"
      isLoading={delieryServicesLoading}>
      <div>
        <ModalList
          label="Punct de lucru"
          searchPlaceholder={"Cauta punct de lucru"}
          modalTitle={"Cauta punct de lucru"}
          items={initialClientsWorkingPointsList}
          modalActions={workingPointModalActions}
          selectedItem={{
            id: selectedWorkingPoint?.id_clienti_pctlucr || "",
            label: selectedWorkingPoint?.denumire || "",
          }}
          setSelectedItem={setSelectedWorkingPoint}
        />

        <ModalList
          label="Punct de ridicare"
          searchPlaceholder={"Cauta punct de ridicare"}
          modalTitle={"Cauta punct de ridicare"}
          items={initialClientsPickupPointsList}
          modalActions={pickupPointModalActions}
          selectedItem={{
            id: selectedPickupPoint?.id_clienti_pctlucr || "",
            label: selectedPickupPoint?.denumire || "",
          }}
          setSelectedItem={setSelectedPickupPoint}
        />
        <Dropdown
          className="mb-4"
          title="Facturi adaugate"
          data={invoicesList}
          multipleValues={invoicesList}
          setMultipleValues={setInvoicesList}
          withAddNewValue
        />

        <div className="d-flex flex-column flex-md-row flex-wrap">
          {packageTypes.map((packageType) => {
            return (
              <TextInput
                style={{ minWidth: 150, maxWidth: 250 }}
                key={packageType.id_serviciu}
                label={packageType.denumire}
                name={packageType.denumire}
                type="number"
                value={packageType.cantitate}
                onChange={(value) =>
                  onPackageChange(value, packageType.id_serviciu)
                }
                withVerticalSpacer
              />
            );
          })}
        </div>
      </div>
      <FormButtons
        goBackLabel="Inapoi"
        goNextLabel="Salveaza"
        nextButtonDisabled={isSubmitDisabled}
        handleGoBack={handleGoBack}
        nextButtonLoading={isLoading}
        handleGoNext={handleSubmit}
      />
    </ContentCard>
  );
}

export default CreateAwb;
