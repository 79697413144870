import { memo, useState } from "react";
import { TableItemId } from "../../../../../components/tables/TableWidget";
import { TableItemType } from "../../../../../components/tables/tableContext/TableContext";
import { useAppDispatch } from "../../../../../redux/hooks";
import { KTSVG } from "../../../../../helpers/KTSVG";
import CustomButton from "../../../../../components/CustomButton";
import { UndeliveredAwbDataInterface } from "../../../../../redux/interfaces/expedition.interface";
import {
  fetchUndeliveredAwb,
  postAssociateAwbToBin,
} from "../../../../../redux/api/expedition.api";
import {
  checkEndpointStatus,
  endpointStatus,
} from "../../../../../helpers/apiHelper";

function UndeliveredAwbTableActionButtons({
  itemId,
  crudData,
  tableContext,
}: {
  itemId: TableItemId;
  tableItemData: TableItemType[];
  crudData?: UndeliveredAwbDataInterface;
  tableContext?: any;
}) {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const handleAssociateAwb = async () => {
    const resultAction = await dispatch(
      postAssociateAwbToBin({
        params: {
          id_doctrans: itemId,
        },
      }),
    );

    if (
      checkEndpointStatus(resultAction, postAssociateAwbToBin) !==
      endpointStatus.pending
    ) {
      setIsLoading(false);
    }

    if (
      checkEndpointStatus(resultAction, postAssociateAwbToBin) ===
      endpointStatus.fulfilled
    ) {
      dispatch(
        fetchUndeliveredAwb({
          page: tableContext.tablePagination.page,
          perPage: tableContext.tablePagination.rowsPerPage,
          pctlivrare: tableContext.tableSearch.pctlivrare,
          nodDestinatie: tableContext.tableFilter.nodDestinatie,
        }),
      );
    }
  };

  return (
    <div>
      {crudData?.id_doctrans && (
        <CustomButton
          loading={isLoading}
          className="me-1  mb-1"
          variant="contained"
          color="primary"
          tooltipTitle="Asociere expeditie"
          withConfirmationModal={{
            modalTitle: "Asociere expeditie",
            modalSubtitle: `Doriti sa adaugati coletul la binurile de pe nodul ${crudData.nodDestinatie}?`,
            modalLeftButtonVariant: "outlined",
            modalRightButtonOnClick: handleAssociateAwb,
          }}>
          <KTSVG
            path="/media/svg/reception/associate.svg"
            className="svg-icon-1"
            fill="white"
          />
        </CustomButton>
      )}
    </div>
  );
}

export default memo(UndeliveredAwbTableActionButtons);
