import { createSlice } from "@reduxjs/toolkit";
import {
  fetchAwbHistory,
  fetchBinHistory,
  fetchGetClientsPickupPoints,
  fetchGetClientsWorkingPoints,
  fetchPackageTypes,
  fetchShowClientsWorkingPoints,
  getServices,
} from "../api/general.api";
import {
  AwbHistoryInterface,
  BinHistoryInterface,
  ClientsWorkingPointsInterface,
  PackageTypeDataInterface,
  PackageTypeInterface,
  ClientWorkingPointsInterface,
  ClientWorkingPointsDataInterface,
  DeliveryServicesInterface,
  DeliveryServicesDataInterface,
} from "../interfaces/general.interface";

export const defaultStateGeneral: {
  packageTypesData: PackageTypeInterface;
  awbHistory: AwbHistoryInterface;
  binHistory: BinHistoryInterface;
  clientsWorkingPoints: ClientsWorkingPointsInterface;
  clientsPickupPoints: ClientsWorkingPointsInterface;
  clientWorkingPoints: ClientWorkingPointsInterface;
  selectedClientWorkingPoint: ClientWorkingPointsDataInterface | null;
  delieryServices: DeliveryServicesInterface;
} = {
  packageTypesData: {
    data: [],
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  awbHistory: {
    data: {
      current_page: 1,
      data: [],
      last_page: 1,
      perPage: 1,
      total: 1,
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  binHistory: {
    data: {
      current_page: 1,
      data: [],
      last_page: 1,
      perPage: 1,
      total: 1,
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  clientsWorkingPoints: {
    data: {
      current_page: 1,
      data: [],
      last_page: 1,
      perPage: 1,
      total: 1,
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  clientsPickupPoints: {
    data: {
      current_page: 1,
      data: [],
      last_page: 1,
      perPage: 1,
      total: 1,
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  clientWorkingPoints: {
    data: {
      current_page: 1,
      data: [],
      last_page: 1,
      perPage: 1,
      total: 1,
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  selectedClientWorkingPoint: null,
  delieryServices: {
    data: [],
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
};

const general = createSlice({
  name: "general",
  initialState: defaultStateGeneral,
  reducers: {
    setSelectedClientWorkingPoint: (state, action) => {
      return {
        ...state,
        selectedClientWorkingPoint: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed\
    builder.addCase(fetchAwbHistory.pending, (state) => {
      return {
        ...state,
        awbHistory: {
          ...defaultStateGeneral.awbHistory,
          isLoading: true,
        },
      };
    });
    builder.addCase(fetchAwbHistory.rejected, (state, action: any) => {
      return {
        ...state,
        awbHistory: {
          ...state.awbHistory,
          isLoading: false,
          errorMessage: action.payload?.message,
          isError: true,
        },
      };
    });
    builder.addCase(fetchAwbHistory.fulfilled, (state, action) => {
      return {
        ...state,
        awbHistory: {
          ...state.awbHistory,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    // awb history end
    // bin history start

    builder.addCase(fetchBinHistory.pending, (state) => {
      return {
        ...state,
        binHistory: {
          ...defaultStateGeneral.binHistory,
          isLoading: true,
        },
      };
    });
    builder.addCase(fetchBinHistory.rejected, (state, action: any) => {
      return {
        ...state,
        binHistory: {
          ...state.binHistory,
          isLoading: false,
          errorMessage: action.payload?.message,
          isError: true,
        },
      };
    });
    builder.addCase(fetchBinHistory.fulfilled, (state, action) => {
      return {
        ...state,
        binHistory: {
          ...state.binHistory,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    // bin history end
    // package type start
    builder.addCase(fetchPackageTypes.pending, (state) => {
      return {
        ...state,
        packageTypesData: {
          ...defaultStateGeneral.packageTypesData,
          isLoading: true,
        },
      };
    });
    builder.addCase(fetchPackageTypes.rejected, (state, action: any) => {
      return {
        ...state,
        packageTypesData: {
          ...defaultStateGeneral.packageTypesData,
          isError: true,
          errorMessage: action.payload.message,
        },
      };
    });
    builder.addCase(
      fetchPackageTypes.fulfilled,
      (
        state,
        action: {
          payload: PackageTypeDataInterface[];
        },
      ) => {
        return {
          ...state,
          packageTypesData: {
            ...defaultStateGeneral.packageTypesData,
            data: action.payload?.map((item) => ({
              ...item,
              label: item.denumire,
              id: item.valoare,
            })),
          },
        };
      },
    );
    // package type end
    // fetch clients working points start
    builder.addCase(fetchGetClientsWorkingPoints.pending, (state) => {
      return {
        ...state,
        clientsWorkingPoints: {
          ...defaultStateGeneral.clientsWorkingPoints,
          isLoading: true,
        },
      };
    });
    builder.addCase(
      fetchGetClientsWorkingPoints.rejected,
      (state, action: any) => {
        return {
          ...state,
          clientsWorkingPoints: {
            ...state.clientsWorkingPoints,
            isLoading: false,
            errorMessage: action.payload?.message,
            isError: true,
          },
        };
      },
    );
    builder.addCase(fetchGetClientsWorkingPoints.fulfilled, (state, action) => {
      return {
        ...state,
        clientsWorkingPoints: {
          ...state.clientsWorkingPoints,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    // fetch clients working points end
    // fetch clients pickup points start
    builder.addCase(fetchGetClientsPickupPoints.pending, (state) => {
      return {
        ...state,
        clientsPickupPoints: {
          ...defaultStateGeneral.clientsPickupPoints,
          isLoading: true,
        },
      };
    });
    builder.addCase(
      fetchGetClientsPickupPoints.rejected,
      (state, action: any) => {
        return {
          ...state,
          clientsPickupPoints: {
            ...state.clientsPickupPoints,
            isLoading: false,
            errorMessage: action.payload?.message,
            isError: true,
          },
        };
      },
    );
    builder.addCase(fetchGetClientsPickupPoints.fulfilled, (state, action) => {
      return {
        ...state,
        clientsPickupPoints: {
          ...state.clientsPickupPoints,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    // fetch clients pickup points end
    // fetch clients working point start
    builder.addCase(fetchShowClientsWorkingPoints.pending, (state) => {
      return {
        ...state,
        clientWorkingPoints: {
          ...defaultStateGeneral.clientWorkingPoints,
          isLoading: true,
        },
      };
    });
    builder.addCase(
      fetchShowClientsWorkingPoints.rejected,
      (state, action: any) => {
        return {
          ...state,
          clientWorkingPoints: {
            ...state.clientWorkingPoints,
            isLoading: false,
            errorMessage: action.payload?.message,
            isError: true,
          },
        };
      },
    );
    builder.addCase(
      fetchShowClientsWorkingPoints.fulfilled,
      (state, action) => {
        return {
          ...state,
          clientWorkingPoints: {
            ...state.clientWorkingPoints,
            isLoading: false,
            data: action.payload,
          },
        };
      },
    );
    //  fetch clients working point end

    // package type start
    builder.addCase(getServices.pending, (state) => {
      return {
        ...state,
        delieryServices: {
          ...defaultStateGeneral.delieryServices,
          isLoading: true,
        },
      };
    });
    builder.addCase(getServices.rejected, (state, action: any) => {
      return {
        ...state,
        delieryServices: {
          ...defaultStateGeneral.delieryServices,
          isError: true,
          errorMessage: action.payload.message,
        },
      };
    });
    builder.addCase(
      getServices.fulfilled,
      (
        state,
        action: {
          payload: { data: DeliveryServicesDataInterface[] };
        },
      ) => {
        return {
          ...state,
          delieryServices: {
            ...defaultStateGeneral.delieryServices,
            data: action.payload?.data.map((item) => ({
              ...item,
              label: item.denumire,
              id: item.id_serviciu,
            })),
          },
        };
      },
    );
    // package type end
  },
});

export const { setSelectedClientWorkingPoint } = general.actions;

export default general.reducer;
