import LoadingIndicator from "../../misc/LoadingIndicator";

const TableLoading = () => {
  return (
    <div
      className="position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center bg-light bg-opacity-50"
      style={{ zIndex: 1050 }}>
      <div className="rounded bg-white text-secondary fw-bold fs-5 p-5 mt-100 h-fit w-content text-center shadow">
        <LoadingIndicator size={50} color="primary" />
      </div>
    </div>
  );
};

export { TableLoading };
